.App{
  font-family: Raleway;
  text-align: center;
  color: antiquewhite;
}
.menu {
  font-size: 30px;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0px 0 8px rgba(0,0,0, .25);
}
.links{
  color: antiquewhite;
  text-decoration: none;
  font-weight: 30;
}
.image{
  width: 15vw;
  height: 25vh;
}
.icons-wrap{
  display:flex;
  width: 200px;
  height: 200px;
  justify-content: center;
}
.icons{
  color: antiquewhite;
  width: 5vw;
  height: 5vh;
  padding: 20px 20px 20px 20px;
}
.home-page{
  top: 50%;
  left: 25%;
  right: 25%;
  position:fixed;
}
.homebutton, .form-button{
  background: #203161;
  font-size: xxx-large;
  justify-content: space-evenly;
  border-color: rgba(0,0,0, 1);
  color: antiquewhite;
}
.is-active, em{
  color: #4c955e;
  font-style: normal;
}
.text-area {
  background-color: #20232f;
  font-size: x-large;
  font-weight: lighter;
  padding: 5px;
  border-width: 3px;
  border-style: wave;
  border-color: rgba(255, 255, 255, 0.10);
}
.background-canvas{
  z-index: -1 !important;
  background: #2a2e3d;
}
.text-container{
  background-color: #20232f;
  box-shadow: rgba(0,0,0, 0.25);
  border-width: 5px;
  border-style: solid;
  border-color: cyan;
  font-size: x-large;
  width: 95%;
}
.text-container:hover {
  transition: all .75ms ease-in-out;
  transform: scale(1.05);
}
.text-container:hover .text-container-inner{
  transition: all 1s ease-in-out;
  visibility: visible;
}
.text-container-inner{
  visibility: hidden;
}
.about-container{
  min-height: 100vh; /* minimum height = screen height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: x-large;
}
.list{
  list-style-type: none;
  margin: 0;
}